<template>
    <div class="visible lg:hidden h-15">
      <nav class="h-15 w-full py-1.5 px-5 flex items-center justify-between fixed start-0 bottom-0 z-10 bg-menu-dark shadow-400">
        <button
          @click="() => router.push(translatePath(ROUTES.HOME))"
          class="flex p-2 h-full items-center justify-center text-light focus:outline-none focus:text-accent-dark hover:text-accent-dark-hover"
        >
          <span class="sr-only">{{ $t('text-home') }}</span>
          <HomeIcon class="w-5 h-5" />
        </button>

        <button
            v-if="search === true"
            @click="ui.toggleMobileSearch"
          class="flex p-2 h-full items-center justify-center text-light focus:outline-none focus:text-accent-dark hover:text-accent-dark-hover"
        >
          <span class="sr-only">{{ $t('text-search') }}</span>
          <SearchIcon class="w-5 h-5" />
        </button>

        <InspirationButton variant="simple" />
        
        <NewsMenuButton variant="simple" />

        <button
          v-if="auth.isLoggedIn"
          @click="() => handleSidebar('AUTH_MENU_VIEW')"
          class="flex p-2 h-full items-center justify-center text-light focus:outline-none focus:text-accent-dark hover:text-accent-dark-hover"
        >
          <span class="sr-only">{{ $t('text-user') }}</span>
          <UserIcon class="w-6 h-6" />
        </button>
        <button
          v-else
          @click="handleAuthModal"
          class="flex p-2 h-full items-center justify-center text-light focus:outline-none focus:text-accent-dark hover:text-accent-dark-hover"
        >
          <span class="sr-only">{{ $t('text-user') }}</span>
          <UserIcon class="w-5 h-5" />
        </button>
      </nav>
    </div>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { useAuthStore } from '@stores/auth';
import { useUIStore } from '@stores/ui';
import { useCartStore } from '@stores/cart';
import { useSelectedStore } from '@stores/selected';
import SearchIcon from "@components/icons/search-icon";
import HomeIcon from "@components/icons/home-icon";
import UserIcon from "@components/icons/user-icon";
import InspirationButton from "@components/layout/navbar/inspiration-button";
import NewsMenuButton from "@components/layout/navbar/news-menu-button";

const props = defineProps({
    search: {
        type: Boolean,
        default: true
    }
});

const { $eventBus } = useNuxtApp();
const router = useRouter();
const auth = useAuthStore();
const ui = useUIStore();
const cart = useCartStore();
const store = useSelectedStore();

const handleSidebar = (view) => {
    $eventBus.emit('sidebar:open', view);
}

const openModal = (view) => {
    $eventBus.emit('modal:open', view);
}

const hadleHome = () => {
    store.setLoadResetAll();
    router.push(translatePath(ROUTES.HOME));
}

const handleAuthModal = () => {
    openModal('LOGIN_VIEW');
}

</script>
